'use client';
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

export const StickyNav = () => {
  const nav = useRef(null);
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    if (nav.current) {
      const observer = new IntersectionObserver(
        ([e]) => {
          setSticky(e.intersectionRatio < 1);
        },
        { threshold: [1] },
      );

      observer.observe(nav.current);

      return () => {
        observer.unobserve(nav.current as any);
        observer.disconnect();
      };
    }
  }, [nav.current]);

  return (
    <div
      className={clsx('sticky-header', {
        ['bg-white border-b is-sticky']: sticky,
      })}
      ref={nav}
    >
      <div className={clsx('max-w-screen-xl mx-auto px-4 gap-4 flex items-center')}>
        <div className="logo">
          <img src="/images/logo-2.svg" alt="Bakker Labs" />
        </div>
        <ul className={`flex m-0 p-0 flex-1 gap-4 items-stretch`}>
          <li>About</li>
          <li>Services</li>
          <li>Contact us</li>
        </ul>
      </div>
    </div>
  );
};
